import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store/store";
import Vuebar from "vuebar";
import "./plugins/base";
import VueSkycons from "vue-skycons";
import InstantSearch from "vue-instantsearch";
import VueFeather from "vue-feather";
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueSweetalert2 from 'vue-sweetalert2';

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetalert2);

Vue.use(VueAxios, axios)
Vue.use(VueFeather);
Vue.use(VueSkycons, {color: "#1e88e5"});
Vue.use(InstantSearch);
Vue.config.productionTip = false;
Vue.use(Vuebar);

new Vue({
    vuetify,
    store,
    router,
    render: (h) => h(App)
}).$mount("#app");
